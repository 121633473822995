@use 'sass:math';

:root {
  --primary-color: #232345;
  --secondary-color: #c8a45d;
  --third-color: #1e2c46;
  --red-color: #d80000;
  --pink-color: #ffe5e5;
  --pink-light-color: #f5f7fb;
  --red-light-color: #fcbcbc;
  --secondary-text-color:#7A7A7A;

  //theme
  --text-color:#000000;
  --border-radius: 9px;


  //various
  --border-radius-sm: 5px;
  --main-width-container: 984px;
  --box-shadow-light: 0px 10px 30px 0px rgba(0,0,0,0.1);
  --box-shadow-dark: 0px 10px 30px 0px rgba(35,35,69,0.3);

  //status colors
  --in-progress-color: #195465;
  --in-progress-bg-color: #a3d8e7;

  --to-cancel-color: #8a1515;
  --to-cancel-bg-color: #ff9898;

  --done-color: #1c2a58;
  --done-bg-color: #5c97f2;

  --waiting-color: #224f5f;
  --waiting-bg-color: #4dc8f3;

  --collected-color: #176f4a;
  --collected-bg-color: #d3ebd5;

  --new-color: #230545;
  --new-bg-color: #9a72fc;

  --ready-color: #185139;
  --ready-bg-color: #66cfa3;

  --canceled-color: #a72323;
  --canceled-bg-color: #ffd9d9;

  // initial check if use
  --pink-primary: #f20789;
  --pink-secondary: #f280bf;
  --pink-tertiary: #f2bdda;
  --blue-primary: #023e73;
  --black-primary: #0d0d0d;
}

// Font
$main-font-family: Poppins, sans-serif;

$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semi-bold: 600;

// Colors

$white: #fff;
$black: #000;
$body-bg-color: #f5f7fb;

// Margins

%m-no {
  margin: 0;
}

%mt-no {
  margin-top: 0;
}

%mb-no {
  margin-bottom: 0;
}

%mb-xxs {
  margin-bottom: 0.25rem;
}

%mb-xs {
  margin-bottom: 0.5rem;
}

%mr-a {
  margin-right: auto
}

%ml-a {
  margin-left: auto
}

// Paddings
