.details-header {
  display: flex;
  justify-content: space-between;
  padding-top: 1.5rem;
  padding-left: 1rem;
  padding-right: 1rem;

  &-title {
    font-size: 1.5rem;
  }

  h1,
  p {
    @extend %mb-xxs;
  }

  .header-left.with-shadow {
    box-shadow: var(--box-shadow-light);
  }

  .header-right.with-shadow {
    box-shadow: var(--box-shadow-dark);
  }
}

.details-footer {
  position: fixed;
  width: 100%;
  bottom: 0;
  padding: 0 30px 0 40px;
  border-radius: var(--border-radius) var(--border-radius) 0 0;
  display: flex;
  margin: 0 auto;
  background: var(--primary-color);
  max-width: var(--main-width-container);
  box-shadow: var(--box-shadow-dark);

  .p-button {
    margin: 10px 15px 10px 0;

    &.p-button-outlined,
    &.button-quit,
    &.p-button-text:enabled:focus,
    &.p-button-outlined:enabled:focus,
    &.p-button-outlined:enabled:hover{
      border-color: $white;
      color: $white;
    }
  }

  button {
    margin: 01.25rem 1rem 01.25rem 0;
  }

  .cta-main {
    margin-right: 0;
    margin-left: auto;

    &_label {
      margin: auto 0 auto auto;
      color: $white;
    }
  }
}
