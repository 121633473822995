body {
  @extend %m-no;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  @extend %mt-no;
  &:last-child {
    @extend %mb-no;
  }
}

a {
  color: inherit;
  &:not(.link) {
    text-decoration: none;
  }
}

button {
  border: none;
  background: none;
}
