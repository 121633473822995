.v-h {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.ta-r {
  text-align: right;
}

.ta-c {
  text-align: center;
}

.ta-l {
  text-align: left;
}

.ws-nw {
  white-space: nowrap;
}

.c-p{
  cursor: pointer;
}

.d-f {
  display: flex;
}

.jc-sb {
  justify-content: space-between;
}

.ml-a {
  margin-left: auto
}

.mr-a {
  margin-right: auto
}

[hidden] {
  display: none !important;
}
