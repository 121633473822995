/* You can add global styles to this file, and also import other style files */
@import '../node_modules/primeicons/primeicons.css';
@import '../node_modules/primeng/resources/themes/md-light-indigo/theme.css';
@import '../node_modules/primeng/resources/primeng.min.css';
@import 'variables';
@import 'reset';
@import 'common';
@import 'theme-override';
@import 'details-page';
@import 'layout';

body {
  font-family: $main-font-family;
  color: var(--black-primary);
  background-color: #f5f7fb;
  touch-action: manipulation; // prevent zoom on double tap
}

a,
.p-button.p-button-text {
  color: var(--primary-color);
}

.p-button {
  background-color: var(--primary-color);
}

button {
  &.link {
    color: var(--primary-color);
    text-decoration: underline;
    font-size: 1rem;
    padding: 0;
    cursor: pointer;
  }
}

.error {
  color: rgb(201, 22, 22);
}

.p-dialog:not(.p-dialog-fullscreen) {
  max-width: 80vw;
}
